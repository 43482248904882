import {
  pause as JwplayerPause,
  play as JwplayerPlay,
  playItem as JwplayerPlayItem,
  unmute as JwplayerUnmute
} from './JWPlayer/controls'

export const isJwplayer = playerType => playerType === 'jwplayer'

export const pause = (playerType, playerId) => {
  isJwplayer(playerType) && JwplayerPause(playerId)
}

export const play = (playerType, playerId) => {
  isJwplayer(playerType) && JwplayerPlay(playerId)
}

export const playItem = (playerType, playerId, index) => {
  isJwplayer(playerType) && JwplayerPlayItem(playerId, index)
}

export const unmute = (playerType, playerId) => {
  isJwplayer(playerType) && JwplayerUnmute(playerId)
}
