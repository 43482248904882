import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import PlayerPlaylistItem from '@bit/orzubalsky.blive.components.player-playlist-item'
import styles from '../Player.module.css'

const Playlist = ({ className, currentIndex, items, onItemClick }) => (
  <div className={clsx(className, styles.playlist)}>
    {items.map(({ durationFormatted, image, label, mediaid }, i) =>
      <PlayerPlaylistItem
        active={currentIndex === i}
        duration={durationFormatted}
        key={mediaid}
        image={image}
        label={label}
        onClick={() => onItemClick(i)}
      />
    )}
  </div>
)

Playlist.propTypes = {
  className: PropTypes.string,
  currentIndex: PropTypes.number,
  items: PropTypes.array.isRequired,
  onItemClick: PropTypes.func.isRequired
}

Playlist.defaultProps = {
  onItemClick: itemIndex => {}
}

export default Playlist
