import React from 'react'
import PropTypes from 'prop-types'
import { useSelector  } from 'react-redux'
import { selectPlaylist, selectPlaylistItemIndex } from '../../selectors'
import { playItem } from '../controls'
import Playlist from './Playlist'

const PlaylistContainer = ({ className, playerId, playerType }) => {
  const items = useSelector(state => selectPlaylist(state, playerId))
  const currentIndex = useSelector(state => selectPlaylistItemIndex(state, playerId))

  if (!items || items.length === 0) return null

  const onItemClick = itemIndex => playItem(playerType, playerId, itemIndex)

  return <Playlist
    className={className}
    currentIndex={currentIndex}
    items={items}
    onItemClick={onItemClick}
  />
}

PlaylistContainer.propTypes = {
  className: PropTypes.string,
  playerId: PropTypes.string.isRequired,
  playerType: PropTypes.string.isRequired
}

export default PlaylistContainer
