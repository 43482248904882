import React from 'react'
import ReactDOM from 'react-dom'
import Manager from 'core/Manager'
import { App } from 'components'
import store from 'app/store'
import { Provider } from 'react-redux'
import * as serviceWorker from './serviceWorker'
import { config } from 'core/config'

if (process.env.NODE_ENV === 'development') {
  const html = {
    akc: `<div class="blive-dcm-embed" blive-id="2061" blive-player-key="Rj3zX0Hf" blive-url="https://www.akc.org/expert-advice/health/why-dogs-eat-poop/" blive-breeds="breedid-412139"></div>`,
    pg: `<div class="blive-dcm-embed" blive-playlist-menu blive-campaign="9000" blive-topics="playerid-412139"></div>`
  }

  const root = document.createElement('div')
  root.innerHTML = html[config.client]

  document.body.appendChild(root)
}

new Manager({
  debug: config.debug,
  onScriptsLoaded: ({ players }) => {
    if (players.length) {
      players.forEach(({ debug, element, urlParams, options, playerId }) => {
        const uniqueId = `blive-dcm-${Math.floor(Math.random() * 10000) + 9999}`

        element.setAttribute('blive-dcm-id', uniqueId)

        ReactDOM.render(
          <Provider store={store} key={playerId}>
            <App
              dcmNodeId={uniqueId}
              debug={debug}
              urlParams={urlParams}
              options={options}
              playerId={playerId}
            />
          </Provider>,
          element
        )
      })
    }
  }
})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
