import trueTypeOf from '@bit/orzubalsky.blive.utils.true-type-of'
import { getIsMute, getPlayer, getState } from './utils'

export const pause = playerId => {
  if (!playerId) throw Error('playerId must be provided')

  if (getState(playerId) !== 'paused') {
    getPlayer(playerId).pause()
  }
}

export const play = playerId => {
  if (!playerId) throw Error('playerId must be provided')

  if (getState(playerId) === 'paused') {
    getPlayer(playerId).play()
  }
}

export const playItem = (playerId, index) => {
  if (!playerId) throw Error('playerId must be provided')
  if (index !== 0 && !index) throw Error('index must be provided')
  if (trueTypeOf(index) !== 'number') throw TypeError('index must be a number')

  getPlayer(playerId).playlistItem(index).play()
}

export const unmute = playerId => {
  if (!playerId) throw Error('playerId must be provided')

  if (getIsMute(playerId)) {
    getPlayer(playerId).setMute(false)
  }
}