import trueTypeOf from '@bit/orzubalsky.blive.utils.true-type-of'

export const getPlayer = playerId => {
  if (!playerId) throw Error('playerId must be provided')

  if (trueTypeOf(playerId) !== 'string') throw TypeError('playerId must be a string')

  if (!window.jwplayer) throw Error('jwplayer script has not been loaded')

  return window.jwplayer(playerId)
}
