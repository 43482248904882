import React from 'react'
import PropTypes from 'prop-types'
import DcmContainer from 'features/dcm/components/DcmContainer'
import Tracking from 'features/tracking/components/Tracking'
import Ui from './Ui'
import ThemeProvider from '@bit/orzubalsky.blive.components.theme-provider'
import { config } from 'core/config'

function App ({ dcmNodeId, options, playerId, urlParams }) {
  const trackingConfig = {
    blive: { isActive: true },
    ga: { isActive: true, id: config.tracking.ga },
    fb: { isActive: false },
    campaignId: config.client === 'akc' ? urlParams.id : urlParams.campaign
  }

  return (
    <div className='BliveApp BliveRoot'>
      <ThemeProvider client={config.client}>
        <DcmContainer
          client={config.client}
          dcmNodeId={dcmNodeId}
          dcmUrl={config.dcmUrl}
          loadingImageDesktop={config.images.loading.desktop}
          loadingImageMobile={config.images.loading.mobile}
          player={config.player}
          playerId={playerId}
          urlParams={urlParams}
        >
          <Tracking key='tracking' config={trackingConfig} />
          <Ui key='ui' playerId={playerId} shouldRenderPlaylistMenu={options.playlistMenu} />
        </DcmContainer>
      </ThemeProvider>
    </div>
  )
}

App.propTypes = {
  debug: PropTypes.bool.isRequired,
  options: PropTypes.object.isRequired,
  playerId: PropTypes.string.isRequired,
  urlParams: PropTypes.object,
}

App.defaultProps = {
  debug: false
}

export default App
