import { getCurrentItem, getPlayer } from '../utils'
import { usePlayerRelatedMenuCloseEvent, usePlayerRelatedMenuOpenEvent } from '../../../hooks'


export const useRelatedMenuCloseEvent = playerId => {
  const onEventWrapper = handler => {
    setTimeout(() => {
      const relatedPlugin = getPlayer(playerId).getPlugin('related')

      if (relatedPlugin) {
        relatedPlugin.on('close', () => handler({ playerId, getCurrentItem: () => getCurrentItem(playerId) }))
      }
    }, 500)
  }

  usePlayerRelatedMenuCloseEvent(onEventWrapper, playerId)
}

export const useRelatedMenuOpenEvent = playerId => {
  const onEventWrapper = handler => {
    setTimeout(() => {
      const relatedPlugin = getPlayer(playerId).getPlugin('related')

      if (relatedPlugin) {
        relatedPlugin.on('open', () => handler({ playerId, getCurrentItem: () => getCurrentItem(playerId) }))
      }
    }, 500)
  }

  usePlayerRelatedMenuOpenEvent(onEventWrapper, playerId)
}

export const useRelatedMenuEvents = playerId => {
  useRelatedMenuCloseEvent(playerId)
  useRelatedMenuOpenEvent(playerId)
}