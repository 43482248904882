import { getCurrentTime, getIsInView, getIsNotPlaying } from '../utils'
import { usePlayerTimeInterval } from '../../../hooks'


export const useUpdateTime = playerId => {
  return usePlayerTimeInterval({
    getPlayerCurrentTime: () => getCurrentTime(playerId),
    getIsNotPlaying: () => getIsNotPlaying(playerId),
    getIsPlayerInView: () => getIsInView(playerId),
    playerId
  })
}
