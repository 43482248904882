import {
  usePlayerAdClickEvent,
  usePlayerAdCompleteEvent,
  usePlayerAdErrorEvent,
  usePlayerAdImpressionEvent,
  usePlayerAdPlayEvent,
  usePlayerAdRequestEvent,
  usePlayerAdSkippedEvent,
  usePlayerAdStartedEvent,
} from '../../../hooks'
import { useJwplayerEvent } from './index'


export const useAdClickEvent = playerId => useJwplayerEvent('adClick', usePlayerAdClickEvent, playerId)

export const useAdCompleteEvent = playerId => useJwplayerEvent('adComplete', usePlayerAdCompleteEvent, playerId)

export const useAdErrorEvent = playerId => useJwplayerEvent('adError', usePlayerAdErrorEvent, playerId)

export const useAdImpressionEvent = playerId => useJwplayerEvent('adImpression', usePlayerAdImpressionEvent, playerId)

export const useAdPlayEvent = playerId => useJwplayerEvent('adPlay', usePlayerAdPlayEvent, playerId)

export const useAdRequestEvent = playerId => useJwplayerEvent('adRequest', usePlayerAdRequestEvent, playerId)

export const useAdSkippedEvent = playerId => useJwplayerEvent('adSkipped', usePlayerAdSkippedEvent, playerId)

export const useAdStartedEvent = playerId => useJwplayerEvent('adStarted', usePlayerAdStartedEvent, playerId)

export const useAdEvents = playerId => {
  useAdClickEvent(playerId)
  useAdCompleteEvent(playerId)
  useAdErrorEvent(playerId)
  useAdImpressionEvent(playerId)
  useAdPlayEvent(playerId)
  useAdRequestEvent(playerId)
  useAdSkippedEvent(playerId)
  useAdStartedEvent(playerId)
}
