import { getPlaylist, getPlaylistItem } from './index'

export const getCurrentItem = playerId => {
  const playlist = getPlaylist(playerId)
  const item = getPlaylistItem(playerId)

  return item
    ? item
    : playlist && playlist.length > 0
      ? playlist[0]
      : {}
}
