import {
  usePlayerBufferedEvent,
  usePlayerEndedEvent,
  usePlayerFullscreenChangedEvent,
  usePlayerIdledEvent,
  usePlayerIsMuteChangedEvent,
  usePlayerPausedEvent,
  usePlayerPlayedEvent,
  usePlayerPlaylistEvent,
  usePlayerSeekedEvent,
  usePlayerVolumeChangedEvent
} from '../../../hooks'
import { useJwplayerEvent } from './index'

export const useBufferedEvent = playerId => useJwplayerEvent('buffer', usePlayerBufferedEvent, playerId)

export const useIdledEvent = playerId => useJwplayerEvent('idle', usePlayerIdledEvent, playerId)

export const usePausedEvent = playerId => useJwplayerEvent('pause', usePlayerPausedEvent, playerId)

export const usePlayedEvent = playerId => useJwplayerEvent('play', usePlayerPlayedEvent, playerId)

export const usePlaylistEvent = playerId => {
  const extraParams = obj => ({ index: obj.index })

  return useJwplayerEvent('playlistItem', usePlayerPlaylistEvent, playerId, extraParams)
}

export const useSeekEvent = playerId => {
  const extraParams = obj => ({ time: obj.offset })

  return useJwplayerEvent('seek', usePlayerSeekedEvent, playerId, extraParams)
}

export const useEndedEvent = playerId => useJwplayerEvent('beforeComplete', usePlayerEndedEvent, playerId)

export const useVolumeChangedEvent = playerId => useJwplayerEvent('volume', usePlayerVolumeChangedEvent, playerId)

export const useIsMuteChangedEvent = playerId => {
  const extraParams = obj => ({ isMute: obj.mute })

  useJwplayerEvent('mute', usePlayerIsMuteChangedEvent, playerId, extraParams)
}

export const useFullscreenEvent = playerId => useJwplayerEvent('fullscreen', usePlayerFullscreenChangedEvent, playerId)


export const useJwplayerEvents = playerId => {
  // buffer event
  useBufferedEvent(playerId)

  // idle event
  useIdledEvent(playerId)

  // pause event
  usePausedEvent(playerId)

  // play event
  usePlayedEvent(playerId)

  // playlist event
  usePlaylistEvent(playerId)

  // seek event
  useSeekEvent(playerId)

  // ended event
  useEndedEvent(playerId)

  // volume event
  useVolumeChangedEvent(playerId)

  // mute event
  useIsMuteChangedEvent(playerId)

  // fullscreen event
  useFullscreenEvent(playerId)
}
