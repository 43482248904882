import {
  getCurrentItem,
  getCurrentItemIndex,
  getCurrentTime,
  getIsInView,
  getIsMute,
  getIsNotPlaying,
  getPlayer,
  getState
} from './index'

export const getPlayerUtilsAsObject = playerId => ({
  getCurrentItem: () => getCurrentItem(playerId),
  getCurrentItemIndex: () => getCurrentItemIndex(playerId),
  getCurrentTime: () => getCurrentTime(playerId),
  getIsInView: () => getIsInView(playerId),
  getIsMute: () => getIsMute(playerId),
  getIsNotPlaying: () => getIsNotPlaying(playerId),
  getPlayer: () => getPlayer(playerId),
  getState: () => getState(playerId),
  playerId
})
