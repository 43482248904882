import { createSlice } from '@reduxjs/toolkit'
import { formatPlaylistItem, makeDcmUrl } from './utils'
import { initialState } from './initialState'

// CONSTANTS
export const NAME = 'dcm'

// SLICE
export const slice = createSlice({
  name: NAME,
  initialState: {},
  reducers: {
    fetchRequest: (state, action) => {
      const { playerId } = action.payload
      state[playerId].isFetching = true
    },
    fetchSuccess: (state, action) => {
      const { items, playerId } = action.payload

      state[playerId].isFetching = false
      state[playerId].isLoaded = true
      state[playerId].items = items
    },
    mount: (state, action) => {
      const { client, dcmUrl, loadingImageDesktop, loadingImageMobile, player, playerId } = action.payload

      const loadingImage = { desktop: loadingImageDesktop, mobile: loadingImageMobile }

      state[playerId] = {
        ...initialState,
        client,
        dcmUrl,
        isMounted: true,
        isLoaded: false,
        loadingImage,
        player,
        playerId
      }
    }
  }
})

// ACTIONS
export const { fetchRequest, fetchSuccess, mount } = slice.actions

// THUNKS
export const fetchItems = (playerId, urlParams) => async (dispatch, getState) => {
  dispatch(fetchRequest({ playerId }))

  const client = selectClient(getState(), playerId)
  const dcmUrl = selectDcmUrl(getState(), playerId)
  const player = selectPlayer(getState(), playerId)

  const url = makeDcmUrl({ params: urlParams, client, dcmUrl })

  const items = await fetch(url).then(response => response.json()).then(json => Object.values(json.data))
  const formattedItems = items.map(item => item && formatPlaylistItem(item, player)).filter(item => item)

  dispatch(fetchSuccess({ items: formattedItems, playerId }))
}

// SELECTORS
const selectSlice = state => state[NAME]
const selectParam = (state, id, param) => selectSlice(state) && selectSlice(state)[id] && selectSlice(state)[id][param]
export const selectClient = (state, id) => selectParam(state, id, 'client')
export const selectDcmUrl = (state, id) => selectParam(state, id, 'dcmUrl')
export const selectIsFetching = (state, id) => selectParam(state, id, 'isFetching')
export const selectIsLoaded = (state, id) => selectParam(state, id, 'isLoaded')
export const selectIsMounted = (state, id) => selectParam(state, id, 'isMounted')
export const selectItems = (state, id) => selectParam(state, id, 'items')
export const selectLoadingImage = (state, id) => selectParam(state, id, 'loadingImage')
export const selectPlayer = (state, id) => selectParam(state, id, 'player')

export default slice.reducer
