import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectIsLoaded, selectQueue } from '../selectors'

const useReportQueue = (name, reportFunc) => {
  const dispatch = useDispatch()
  const isLoaded = useSelector(state => selectIsLoaded(state, name))
  const queue = useSelector(state => selectQueue(state, name))

  useEffect(() => {
    if (!isLoaded) return

    if (queue.length === 0) return

    dispatch(reportFunc())
  }, [ dispatch, isLoaded, queue, reportFunc ])
}

export default useReportQueue