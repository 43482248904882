import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setSessionKey } from '../actions'
import { randomString } from '../utils'

const useSessionKey = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    let sessionKey = window.sessionStorage['blive_session_key']

    if (!sessionKey) {
      window.sessionStorage['blive_session_key'] = randomString()
    }

    dispatch(setSessionKey({ sessionKey }))
  }, [ dispatch ])
}

export default useSessionKey