import React from 'react'
import PropTypes from 'prop-types'
import styles from './Dcm.module.css'

const LoadingImage = ({ src }) => (
  <div
    className={styles.image}
    style={{ backgroundImage: `url(${src})` }}
  />
)

LoadingImage.propTypes = {
  src: PropTypes.string.isRequired,
}

export default LoadingImage