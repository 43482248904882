import { createReducer } from '@reduxjs/toolkit'
import { BLIVE, EVENTS, FACEBOOK, GA } from './constants'
import { initialState } from './initialState'
import { getLocation, itemParams, pushToQueue } from './utils'
import {
  gaLoaded,
  mount,
  removeFromQueue,
  setSessionKey,
  setUser
} from './actions'
import {
  playerAdClicked,
  playerAdCompleted,
  playerAdImpression,
  playerAdPlayed,
  playerAdSkipped,
  playerAdStarted,
  playerEmbedIntended,
  playerEnded,
  playerFullscreenChanged,
  playerLoaded,
  playerPlayed,
  playerPlaybackButtonClicked,
  playerPlaylistItem,
  playerRelatedMenuOpened,
  playerShareDialogOpened,
  playerShareIntended,
  playerVolumeChanged,
  playerWindowClosed
} from 'features/player/actions'


export const reducer = createReducer(initialState, {
  [ gaLoaded ]: state => {
    state[GA].isLoaded = true
  },
  [ mount ]: (state, action) => {
    const { config = {} } = action.payload
    const { blive, campaignId, facebook, ga } = config

    state[BLIVE] = { ...state[BLIVE], ...blive }
    state[FACEBOOK] = { ...state[FACEBOOK], ...facebook }
    state[GA] = {...state[GA], ...ga }
    state.campaignId = campaignId
    state.isMounted = true
  },
  [ removeFromQueue ]: (state, action) => {
    const { id, name } = action.payload

    if (!state[name] || !state[name].queue) return state

    const index = state[name].queue.findIndex(e => e.id === id)

    if (index >= 0) {
      state[name].queue.splice(index, 1)
    }
  },
  [ setSessionKey ]: (state, action) => {
    state.sessionKey = action.payload.sessionKey
  },
  [ setUser ]: (state, action) => {
    state.user = action.payload.user
    state[BLIVE].isLoaded = true
  },
  [ playerAdClicked ]: (state, action) => {
    const { id, itemId, itemLabel } = itemParams(state, action)

    pushToQueue(state, BLIVE, { etid: EVENTS.AD_CLICKED, eoid: itemId, val: state.type, id })
    pushToQueue(state, FACEBOOK, { etid: EVENTS.AD_CLICKED, eoid: itemId, val: state.type, label: itemLabel, id })
    pushToQueue(state, GA, { event_category: 'AD_CLICKED', event_action: itemLabel, event_label: state.type, id })
  },
  [ playerAdCompleted ]: (state, action) => {
    const { id, itemId, itemLabel } = itemParams(state, action)

    pushToQueue(state, BLIVE, { etid: EVENTS.AD_ENDED, eoid: itemId, val: state.type, id })
    pushToQueue(state, FACEBOOK, { etid: EVENTS.AD_ENDED, eoid: itemId, val: state.type, label: itemLabel, id })
    pushToQueue(state, GA, { event_category: 'AD_ENDED', event_action: itemLabel, event_label: state.type, id })
  },
  [ playerAdImpression ]: (state, action) => {
    const { id, itemId, itemLabel } = itemParams(state, action)

    pushToQueue(state, BLIVE, { etid: EVENTS.AD_IMPRESSION, eoid: itemId, val: state.type, id })
    pushToQueue(state, FACEBOOK, { etid: EVENTS.AD_IMPRESSION, eoid: itemId, val: state.type, label: itemLabel, id })
    pushToQueue(state, GA, { event_category: 'AD_IMPRESSION', event_action: itemLabel, event_label: state.type, id })
  },
  [ playerAdPlayed ]: (state, action) => {
    const { id, itemId, itemLabel } = itemParams(state, action)

    pushToQueue(state, BLIVE, { etid: EVENTS.AD_PLAYED, eoid: itemId, val: state.type, id })
    pushToQueue(state, FACEBOOK, { etid: EVENTS.AD_PLAYED, eoid: itemId, val: state.type, label: itemLabel, id })
    pushToQueue(state, GA, { event_category: 'AD_PLAYED', event_action: itemLabel, event_label: state.type, id })
  },
  [ playerAdSkipped ]: (state, action) => {
    const { id, itemId, itemLabel } = itemParams(state, action)

    pushToQueue(state, BLIVE, { etid: EVENTS.AD_ENDED, eoid: itemId, val: state.type, id })
    pushToQueue(state, FACEBOOK, { etid: EVENTS.AD_ENDED, eoid: itemId, val: state.type, label: itemLabel, id })
    pushToQueue(state, GA, { event_category: 'AD_ENDED', event_action: itemLabel, event_label: state.type, id })
  },
  [ playerAdStarted ]: (state, action) => {
    const { id, itemId, itemLabel } = itemParams(state, action)

    pushToQueue(state, BLIVE, { etid: EVENTS.AD_LOADED, eoid: itemId, val: state.type, id })
    pushToQueue(state, FACEBOOK, { etid: EVENTS.AD_LOADED, eoid: itemId, val: state.type, label: itemLabel, id })
    pushToQueue(state, GA, { event_category: 'AD_LOADED', event_action: itemLabel, event_label: state.type, id })
  },
  [ playerEmbedIntended ] : (state, action) => {
    const { id, itemId, itemLabel } = itemParams(state, action)
    const value = state.type === 'dcm' ? 'dcm' : null

    pushToQueue(state, BLIVE, { etid: EVENTS.TV_EMBED_INTENT, eoid: itemId, val: value, id })
    pushToQueue(state, FACEBOOK, { etid: EVENTS.EVENTS.TV_EMBED_INTENT, eoid: itemId, val: value, label: itemLabel, id })
    pushToQueue(state, GA, { event_category: 'TV_EMBED_INTENT', event_action: itemLabel, event_label: value, id })
  },
  [ playerEnded ]: (state, action) => {
    const { id, itemId, itemLabel } = itemParams(state, action)

    pushToQueue(state, BLIVE, { etid: EVENTS.VIDEO_ENDED, eoid: itemId, val: state.type, id })
    pushToQueue(state, FACEBOOK, { etid: EVENTS.VIDEO_ENDED, eoid: itemId, val: state.type, label: itemLabel, id })
    pushToQueue(state, GA, { event_category: 'VIDEO_ENDED', event_action: itemLabel, event_label: state.type, id })
  },
  [ playerFullscreenChanged ] : (state, action) => {
    const { id, itemId, itemLabel } = itemParams(state, action)

    if (state.type === 'dcm') {
      // Track dcm engagement
      pushToQueue(state, BLIVE, { etid: EVENTS.TV_DCM_ENGAGEMENT, eoid: itemId, val: 'fullscreen', id })
      pushToQueue(state, FACEBOOK, { etid: EVENTS.TV_DCM_ENGAGEMENT, eoid: itemId, val: 'fullscreen', label: itemLabel, id })
      pushToQueue(state, GA, { event_category: 'TV_DCM_ENGAGEMENT', event_action: itemLabel, event_label: 'fullscreen', id })
    }
  },
  [ playerLoaded ]: (state, action) => {
    const { id, itemId, itemLabel } = itemParams(state, action)

    pushToQueue(state, BLIVE, { etid: EVENTS.PLAYER_LOADED, eoid: itemId, val: state.type, id })
    pushToQueue(state, FACEBOOK, { etid: EVENTS.PLAYER_LOADED, eoid: itemId, val: state.type, label: itemLabel, id })
    pushToQueue(state, GA, { event_category: 'PLAYER_LOADED', event_action: itemLabel, event_label: state.type, id })
  },
  [ playerPlaybackButtonClicked ]: (state, action) => {
    if (state.type === 'dcm') {
      const { id, itemId, itemLabel } = itemParams(state, action)
      const { value } = action.payload

      pushToQueue(state, BLIVE, { etid: EVENTS.TV_DCM_ENGAGEMENT, eoid: itemId, val: value, id })
      pushToQueue(state, FACEBOOK, { etid: EVENTS.TV_DCM_ENGAGEMENT, eoid: itemId, val: value, label: itemLabel, id })
      pushToQueue(state, GA, { event_category: 'TV_DCM_ENGAGEMENT', event_action: itemLabel, event_label: value, id })
    }
  },
  [ playerPlayed ]: (state, action) => {
    const { id, itemDidPlay, itemId, itemLabel } = itemParams(state, action)

    if (!itemDidPlay) {
      pushToQueue(state, BLIVE, { etid: EVENTS.PLAYER_PLAYED, eoid: itemId, val: state.type, id })
      pushToQueue(state, FACEBOOK, { etid: EVENTS.PLAYER_PLAYED, eoid: itemId, val: state.type, label: itemLabel, id })
      pushToQueue(state, GA, { event_category: 'PLAYER_PLAYED', event_action: itemLabel, event_label: state.type, id })
    }
  },
  [ playerPlaylistItem ]: (state, action) => {
    const { id, index, itemId, itemLabel } = itemParams(state, action)

    // Track playlist depth
    pushToQueue(state, BLIVE, { etid: EVENTS.PLAYLIST_DEPTH, eoid: itemId, val: index + 1, id })
    pushToQueue(state, FACEBOOK, { etid: EVENTS.PLAYLIST_DEPTH, eoid: itemId, val: index + 1, label: itemLabel, id })
    pushToQueue(state, GA, { event_category: 'PLAYLIST_DEPTH', event_action: getLocation(), event_label: itemId, value: index + 1, id })

    if (state.type === 'dcm') {
      // Track dcm engagement
      pushToQueue(state, BLIVE, { etid: EVENTS.TV_DCM_ENGAGEMENT, eoid: itemId, val: 'playlist-menu', id })
      pushToQueue(state, FACEBOOK, { etid: EVENTS.TV_DCM_ENGAGEMENT, eoid: itemId, val: 'playlist-menu', label: itemLabel, id })
      pushToQueue(state, GA, { event_category: 'TV_DCM_ENGAGEMENT', event_action: itemLabel, event_label: 'playlist-menu', id })
    }
  },
  [ playerRelatedMenuOpened ] : (state, action) => {
    if (state.type === 'dcm') {
      const { id, itemId, itemLabel } = itemParams(state, action)

      // Track dcm engagement
      pushToQueue(state, BLIVE, { etid: EVENTS.TV_DCM_ENGAGEMENT, eoid: itemId, val: 'playlist-menu', id })
      pushToQueue(state, FACEBOOK, { etid: EVENTS.TV_DCM_ENGAGEMENT, eoid: itemId, val: 'playlist-menu', label: itemLabel, id })
      pushToQueue(state, GA, { event_category: 'TV_DCM_ENGAGEMENT', event_action: itemLabel, event_label: 'playlist-menu', id })
    }
  },
  [ playerShareDialogOpened ] : (state, action) => {
    if (state.type === 'dcm') {
      const { id, itemId, itemLabel } = itemParams(state, action)

      // Track dcm engagement
      pushToQueue(state, BLIVE, { etid: EVENTS.TV_DCM_ENGAGEMENT, eoid: itemId, val: 'share-menu', id })
      pushToQueue(state, FACEBOOK, { etid: EVENTS.TV_DCM_ENGAGEMENT, eoid: itemId, val: 'share-menu', label: itemLabel, id })
      pushToQueue(state, GA, { event_category: 'TV_DCM_ENGAGEMENT', event_action: itemLabel, event_label: 'share-menu', id })
    }
  },
  [ playerShareIntended ] : (state, action) => {
    const { id, itemId, itemLabel } = itemParams(state, action)
    const { name } = action.payload

    pushToQueue(state, BLIVE, { etid: EVENTS.TV_SHARE_INTENT, eoid: itemId, val: name, id })
    pushToQueue(state, FACEBOOK, { etid: EVENTS.EVENTS.TV_SHARE_INTENT, eoid: itemId, val: name, label: itemLabel, id })
    pushToQueue(state, GA, { event_category: 'TV_SHARE_INTENT', event_action: itemLabel, event_label: name, id })
  },
  [ playerVolumeChanged ] : (state, action) => {
    const { id, itemId, itemLabel } = itemParams(state, action)

    if (state.type === 'dcm') {
      // Track dcm engagement
      pushToQueue(state, BLIVE, { etid: EVENTS.TV_DCM_ENGAGEMENT, eoid: itemId, val: 'volume', id })
      pushToQueue(state, FACEBOOK, { etid: EVENTS.TV_DCM_ENGAGEMENT, eoid: itemId, val: 'volume', label: itemLabel, id })
      pushToQueue(state, GA, { event_category: 'TV_DCM_ENGAGEMENT', event_action: itemLabel, event_label: 'volume', id })
    }
  },
  [ playerWindowClosed ] : (state, action) => {
    const { id, itemId, itemLabel } = itemParams(state, action)
    const { inViewElapsed, playlistDepthInView } = action.payload

    // track playlist time in view
    pushToQueue(state, BLIVE, { etid: EVENTS.PLAYLIST_TIME_IN_VIEW, eoid: itemId, val: inViewElapsed, id })
    pushToQueue(state, FACEBOOK, { etid: EVENTS.PLAYLIST_TIME_IN_VIEW, eoid: itemId, val: inViewElapsed, label: itemLabel, id })
    pushToQueue(state, GA, { event_category: 'PLAYLIST_TIME_IN_VIEW', event_action: getLocation(), event_label: itemId, value: inViewElapsed, id })

    // track playlist depth in view
    pushToQueue(state, BLIVE, { etid: EVENTS.PLAYLIST_DEPTH_IN_VIEW, eoid: itemId, val: playlistDepthInView, id })
    pushToQueue(state, FACEBOOK, { etid: EVENTS.PLAYLIST_DEPTH_IN_VIEW, eoid: itemId, val: playlistDepthInView, label: itemLabel, id })
    pushToQueue(state, GA, { event_category: 'PLAYLIST_DEPTH_IN_VIEW', event_action: getLocation(), event_label: itemId, value: playlistDepthInView, id })
  }
})
