import React, {  useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { fetchItems, mount, selectItems, selectIsLoaded, selectIsMounted } from '../dcmSlice'
import Dcm from './Dcm'

const DcmContainer = ({
  children,
  client,
  dcmNodeId,
  dcmUrl,
  loadingImageDesktop,
  loadingImageMobile,
  player,
  playerId,
  urlParams
}) => {
  const isLoaded = useSelector(state => selectIsLoaded(state, playerId))
  const isMounted = useSelector(state => selectIsMounted(state, playerId))
  const items = useSelector(state => selectItems(state, playerId))

  const dispatch = useDispatch()

  // Mount options
  useEffect(() => {
    dispatch(mount({ client, dcmUrl, loadingImageDesktop, loadingImageMobile, player, playerId }))
  }, [ client, dcmUrl, loadingImageDesktop, loadingImageMobile, player, playerId, dispatch ])

  // Fetch items
  useEffect(() => {
    if (!urlParams || !isMounted || isLoaded ) return

    dispatch(fetchItems(playerId, urlParams))
  }, [ dispatch, isLoaded, isMounted, playerId, urlParams ])

  // Write item total count into root node
  useEffect(() => {
    if (!items || !isLoaded) return

    // Find root node based on `blive-dcm-id` attribute, which is set in index.js
    const rootNode = document.querySelector(`[blive-dcm-id=${dcmNodeId}]`)

    if (!rootNode) return

    // Write an attribute with total items to root node
    rootNode.setAttribute('blive-total-items', items.length)
  }, [ dcmNodeId, isLoaded, items ])

  return (
    <Dcm items={items} playerId={playerId}>
      {children}
    </Dcm>
  )
}

DcmContainer.propTypes = {
  children: PropTypes.node,
  client: PropTypes.string.isRequired,
  dcmUrl: PropTypes.string.isRequired,
  loadingImageDesktop: PropTypes.string.isRequired,
  loadingImageMobile: PropTypes.string,
  player: PropTypes.string.isRequired,
  playerId: PropTypes.string.isRequired,
  urlParams: PropTypes.object,
}

export default DcmContainer