import React from 'react'
import PropTypes from 'prop-types'
import ShareDialog from '@bit/orzubalsky.blive.components.share-dialog'
import { useSelector, useDispatch } from 'react-redux'
import {
  playerEmbedIntended,
  playerShareDialogClosed,
  playerShareDialogOpened,
  playerShareIntended
} from '../../actions'
import {
  selectCurrentItem,
  selectCurrentLabel,
  selectCurrentLink,
  selectCurrentSlug,
  selectIsShareDialogOpen,
  selectIsShareDialogVisible
} from '../../selectors'
import { pause, play } from '../controls'

const ShareDialogContainer = ({ appUrl, facebookAppId, playerId, playerType }) => {
  const dispatch = useDispatch()
  const item = useSelector(state => selectCurrentItem(state, playerId))

  const closeHandler = () => {
    dispatch(playerShareDialogClosed({ item, playerId }))
    play(playerType, playerId)
  }

  const openHandler = () => {
    dispatch(playerShareDialogOpened({item, playerId }))
    pause(playerType, playerId)
  }

  const shareHandler = name => {
    dispatch(playerShareIntended({ item, name, playerId }))
  }

  const embedHandler = () => {
    dispatch(playerEmbedIntended({ item, playerId }))
  }

  const label = useSelector(state => selectCurrentLabel(state, playerId))

  const description = useSelector(state => selectCurrentLabel(state, playerId))

  const link = useSelector(state => selectCurrentLink(state, playerId))

  const slug = useSelector(state => selectCurrentSlug(state, playerId))

  const embedUrl = `https://${appUrl}/embed/${link}/${slug}`

  const shareUrl = `https://${appUrl}/watch/${link}/${slug}`

  const isOpen = useSelector(state => selectIsShareDialogOpen(state, playerId))

  const isVisible = useSelector(state => selectIsShareDialogVisible(state, playerId))

  if (!isVisible) return null

  return (
    <ShareDialog
      closeHandler={closeHandler}
      description={description}
      embedUrl={embedUrl}
      facebookAppId={facebookAppId}
      handleEmbedIntent={embedHandler}
      handleShareIntent={shareHandler}
      isOpen={isOpen}
      label={label}
      openHandler={openHandler}
      shareUrl={shareUrl}
    />
  )
}

ShareDialogContainer.propTypes = {
  appUrl: PropTypes.string.isRequired,
  facebookAppId: PropTypes.number,
  playerId: PropTypes.string.isRequired,
  playerType: PropTypes.string.isRequired
}

export default ShareDialogContainer
