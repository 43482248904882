import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { BLIVE, FACEBOOK, GA } from '../constants'
import { mount } from '../actions'
import { selectIsMounted } from '../selectors'
import { initialState } from '../initialState'
import BliveTracker from './BliveTracker'
import FacebookTracker from './FacebookTracker'
import GaTracker from './GaTracker'

const Tracking = ({ config }) => {
  const isMounted = useSelector(selectIsMounted)

  const dispatch = useDispatch()
  useEffect(() => {
    if (!isMounted) {
      dispatch(mount({ config }))
    }
  }, [ config, dispatch, isMounted ])

  if (!isMounted) return null

  return (
    <>
      {config[BLIVE].isActive ? <BliveTracker /> : null}
      {config[FACEBOOK].isActive ? <FacebookTracker /> : null}
      {config[GA].isActive ? <GaTracker /> : null}
    </>
  )
}

Tracking.propTypes = {
  config: PropTypes.object.isRequired
}

Tracking.defaultProps = {
  config: initialState
}

export default Tracking