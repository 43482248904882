import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { fetchUser } from '../thunks'

const useUserAgent = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchUser())
  }, [ dispatch ])}

export default useUserAgent