import { createReducer } from '@reduxjs/toolkit'
import { initialState } from './initialState'
import {
  resetTime,
  playerAdCompleted,
  playerAdError,
  playerAdRequested,
  playerAdSkipped,
  playerBuffered,
  playerIdled,
  playerIsMuteChanged,
  playerLoaded,
  playerMounted,
  playerPaused,
  playerPlayed,
  playerPlaylistItem,
  playerRelatedMenuOpened,
  playerRelatedMenuClosed,
  playerShareDialogClosed,
  playerShareDialogOpened,
  setTime
} from './actions'

export const reducer = createReducer({}, {
[ resetTime ]: (state, action) => {
  const { playerId } = action.payload

  state[playerId].time = {
    ...state[playerId].time,
    current: 0,
    elapsed: 0,
    last: null,
    lastElapsed: null
  }
},
[ playerAdCompleted ]: (state, action) => {
  const { playerId } = action.payload

  // hide ad teaser
  state[playerId].isTeaserVisible = false
},
[ playerAdError ]: (state, action) => {
  const { playerId } = action.payload

  // hide ad teaser
  state[playerId].isTeaserVisible = false
},
[ playerAdRequested ]: (state, action) => {
  const { playerId } = action.payload

  // show teaser
  state[playerId].isTeaserVisible = true
},
[ playerAdSkipped ]: (state, action) => {
  const { playerId } = action.payload

  // hide teaser
  state[playerId].isTeaserVisible = false
},
[ playerBuffered ]: (state, action) => {
  const { playerId } = action.payload
  state[playerId].playerState = 'buffering'
},
[ playerIdled ]: (state, action) => {
  const { playerId } = action.payload
  state[playerId].playerState = 'idle'
},
[ playerIsMuteChanged ]: (state, action) => {
  const { isMute, playerId } = action.payload
  state[playerId].isMute = isMute
},
[ playerLoaded ]: (state, action) => {
  const { isMute, playerId, playlist } = action.payload

  state[playerId].isLoaded = true
  state[playerId].isMute = isMute
  state[playerId].playerId = playerId
  state[playerId].playlist = playlist
},
[ playerPaused ]: (state, action) => {
  const { playerId } = action.payload

  state[playerId].playerState = 'paused'
},
[ playerMounted ]: (state, action) => {
  const {
    items: playlist,
    playerType,
    playerId,
  } = action.payload

  state[playerId] = {
    ...initialState,
    isMounted: true,
    playlist,
    playerType,
    playerId
  }
},
[ playerPlayed ]: (state, action) => {
  const { index, isMute, itemDidPlay, playerId } = action.payload

  // update playlist index
  state[playerId].playlistItemIndex = index

  // update mute state
  state[playerId].isMute = isMute

  if (!itemDidPlay) {
    // set item didplay state
    state[playerId].playlist[state[playerId].playlistItemIndex].didPlay = true

    // set player state
    state[playerId].playerState = 'playing'
  }
},
[ playerPlaylistItem ]: (state, action) => {
  const { index, isInView, playerId } = action.payload

  // update playlist index
  state[playerId].playlistItemIndex = index

  // reset time
  state[playerId].time = {
    ...state[playerId].time,
    current: 0,
    elapsed: 0,
    last: null,
    lastElapsed: null
  }

  if (isInView) {
    // increment playlist depth in view count
    state[playerId].playlistDepthInView = state[playerId].playlistDepthInView + 1
  }
},
[ playerRelatedMenuClosed ]: (state, action) => {
  const { playerId } = action.payload

  // set related menu state
  state[playerId].isRelatedMenuOpen = false
},
[ playerRelatedMenuOpened ]: (state, action) => {
  const { playerId } = action.payload

  // set related menu state
  state[playerId].isRelatedMenuOpen = true
},
[ playerShareDialogClosed ]: (state, action) => {
  const { playerId } = action.payload

  // set share dialog state
  state[playerId].isShareDialogOpen = false
},
[ playerShareDialogOpened ]: (state, action) => {
  const { playerId } = action.payload

  // set share dialog state
  state[playerId].isShareDialogOpen = true
},
[ setTime ]: (state, action) => {
  const { seconds, isInView, playerId } = action.payload

  const { current, last, elapsed, playlistElapsed, inViewElapsed } = state[playerId].time

  if (seconds > 0 && seconds !== current) {
    const timeDiff = current - last

    const updatedTime = (diff, last) => diff > 0 && diff < 2 ? last + diff : last

    const updatedElapsedTime = updatedTime(timeDiff, elapsed)
    const updatedPlaylistElapsedTime = updatedTime(timeDiff, playlistElapsed)

    const updatedInViewElapsedTime = isInView
      ? updatedTime(timeDiff, inViewElapsed)
      : inViewElapsed

      state[playerId].time = {
        ...state[playerId].time,
        last: current,
        lastElapsed: elapsed,
        lastPlaylistElapsed: playlistElapsed,
        lastInViewElapsed: inViewElapsed,
        current: seconds,
        elapsed: updatedElapsedTime,
        playlistElapsed: updatedPlaylistElapsedTime,
        inViewElapsed: updatedInViewElapsedTime
      }
    }
  }
})
