import { log } from '@bit/orzubalsky.blive.utils.log'
import { isBitmovin, isBrid, isJwplayer, loadScript } from 'core/utils'
import { config } from 'core/config'
const EventEmitter = require('events')

const EVENTS = {
  scriptsLoaded: 'scriptsLoaded'
}

class Emitter extends EventEmitter {}

export default class Manager {
  constructor(options = {}) {
    const scriptParams = this.getParamsFromScript()

    const debug = scriptParams['debug']
      ? scriptParams['debug'] === 'true' ? true : false
      : options.debug || false

    const async = scriptParams['async']
      ? scriptParams['async'] === 'true' ? true : false
      : options.async || false;

    Object.assign(this, {
      containerSelector: '.blive-dcm-embed',
      optionKeys: [ 'playlist-menu' ],
      emitter: new Emitter(),
      prefix: 'blive-',
      onScriptsLoaded: () => {},
      players: [],
    }, options, { async, debug })

    this.emitter.on(EVENTS.scriptsLoaded, () => {
      log('Scripts: loaded')
      this.onScriptsLoaded(this)
    })

    if (!this.async) {
      this.init()
    }
  }

  init () {
    log('BliveDCM initializing', this.containerSelector)

    const containerElements = document.querySelectorAll(this.containerSelector)

    const playerKeys = Array.from(containerElements).map(c => this.getProp(c, 'player-key'))

    const playerKey = playerKeys.find(value => !!value)

    this.loadScripts({ jwplayerKey: playerKey })

    containerElements.forEach((c, i) => {
      const player = {
        element: c,
        debug: this.debug,
        playerId: `blive_player_${Math.floor(Math.random() * 10000) + 10000}`,
        options: { ...this.getOptions(c) },
        urlParams: { ...this.getUrlParams(c) }
      }

      this.players.push(player)
    })
  }

  getParamsFromScript () {
    const params = {}
    const scripts = document.getElementsByTagName('script')

    for (let i = 0; i < scripts.length; i++) {
      const src = scripts[i].getAttribute('src');
      if (src && src.indexOf('blive-dcm-player.js') > -1 && src.indexOf('?') > -1) {
        src.split('?')[1].split('&').map(p => p.split('=')).forEach(arr => {
          params[arr[0]] = arr[1]
        })
      }
    }

    return params
  }

  async loadScripts ({ jwplayerKey } = {}) {
    log('Scripts: loading')

    const scripts = [
      isJwplayer ? `https://cdn.jwplayer.com/libraries/${jwplayerKey || config.jwplayer.libraryKey}.js` : null,
      isBitmovin ? `//cdn.bitmovin.com/player/web/8/bitmovinplayer.js` : null,
      isBrid ? `//services.brid.tv/player/build/brid.min.js` : null
    ].filter(url => url != null)

    const promises = scripts.map(source => loadScript(source)
      .then(() => log(`${source}: loaded`))
      .catch(() => {
        log(`Scripts: failed to load ${source}.`)
        this.loadScripts()
      })
    )

    try {
      await Promise.all(promises)

      if (isJwplayer) {
        // set jwplayer key
        window.jwplayer.key = config.jwplayer.key
      }

      // fire scripts loaded event
      this.emitter.emit(EVENTS.scriptsLoaded)
    } catch (e) {}
  }

  getOptions (element) {
    const { 'playlist-menu': playlistMenu } = this.getProps(element)

    // set option to true if html attr is either present or set explicitly to 'true'
    return {  playlistMenu: (playlistMenu || playlistMenu === '') ? (playlistMenu === '' || playlistMenu === 'true') ? true : false : undefined }
  }

  getUrlParams (element) {
    let attrs = this.getProps(element)

    this.optionKeys.forEach(key => delete attrs[key])

    return attrs
  }

  getProp (element, propName) {
    return this.getProps(element)[propName]
  }

  getProps (element) {
    const propArray = [].slice.call(element.attributes).filter(attr => attr && attr.name && attr.name.indexOf(this.prefix) > -1)
    return propArray.reduce((obj, item) => {
      const key = item['name'].substr(this.prefix.length)
      obj[key] = item['value']
      return obj
    }, {})
  }
}