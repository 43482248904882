import trueTypeOf from '@bit/orzubalsky.blive.utils.true-type-of'
import { getPlayer, getPlayerUtilsAsObject } from '../utils'

export const useJwplayerEvent = (eventName, hook, playerId, extraParams = () => {}) => {
  // validate args
  if (!eventName) throw Error('eventName must be provided')
  if (!hook) throw Error('hook must be provided')
  if (!playerId) throw Error('playerId must be provided')

  // validate arg types
  if (trueTypeOf(eventName) !== 'string') throw TypeError('eventName must be a string')
  if (trueTypeOf(hook) !== 'function') throw TypeError('hook must be a function')
  if (trueTypeOf(playerId) !== 'string') throw TypeError('playerId must be a string')
  if (extraParams && trueTypeOf(extraParams) !== 'function') throw TypeError('extraParams must be a function')

  const defaultParams = () => getPlayerUtilsAsObject(playerId)
  const params = obj => ({
    ...defaultParams(playerId),
    ...extraParams(obj)
  })

  return hook(handler => getPlayer(playerId).on(eventName, obj => handler(params(obj))), playerId)
}
