import formatDuration from '@bit/orzubalsky.blive.utils.format-duration'
import { default as isMobile } from '@bit/orzubalsky.blive.utils.is-mobile'

const formatBridItem = (entity, playlistUrl) => {
  return {
    title: entity.label,
    image: entity.poster_thumbnail_url,
    thumbnail: entity.poster_thumbnail_url,
    videoId: entity.id,
    source: {
      sd: `//${entity.active_mobile_playlist_url}`,
      hd: playlistUrl
    }
  }
}

const formatBitmovinItem = (entity, playlistUrl) => {
  return {
    title: entity.label,
    description: entity.description,
    hls: playlistUrl,
    poster: entity.poster_thumbnail_url
  }
}

const formatJwplayerItem = (entity, playlistUrl) => {
  const item = {
    file: playlistUrl,
    image: entity.poster_thumbnail_url,
    title: entity.label,
    description: entity.description,
  }

  return entity.close_caption_path
    ? {
      ...item,
      tracks: [{
        file: entity.close_caption_path,
        label: 'English',
        kind: 'captions',
        'default': false
      }]
    }
    : item
}

const formatPlaylistItem = (item, player) => {
  if (!player) throw Error('player argument missing')
  if (!['jwplayer', 'bitmovin', 'brid'].includes(player)) throw Error('player must be one of: jwplayer, bitmovin, brid')

  const playlistUrl = `//${!isMobile() ? item.active_playlist_url : item.active_mobile_playlist_url}`

  let playerSpecificKeys

  if (player === 'jwplayer') playerSpecificKeys = formatJwplayerItem(item, playlistUrl)
  if (player === 'bitmovin') playerSpecificKeys = formatBitmovinItem(item, playlistUrl)
  if (player === 'brid') playerSpecificKeys = formatBridItem(item, playlistUrl)

  const { duration, active_media_type, label, link, id: mediaid, slug } = item

  const durationInt = parseInt(duration, 10)

  const formattedItem =  {
    ...playerSpecificKeys,
    duration: durationInt,
    isLive: active_media_type === 'broadcast',
    label,
    link,
    mediaid,
    slug
  }

  return durationInt || durationInt === 0 ? { ...formattedItem, durationFormatted: formatDuration(durationInt) } : formattedItem
}

export default formatPlaylistItem