import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import trueTypeOf from '@bit/orzubalsky.blive.utils.true-type-of'
import {
  getConfig,
  getCurrentItem,
  getIsMute,
  getPlayer
} from '../utils'
import { playerWindowClosedRequested } from '../../../thunks'
import { playerLoaded } from '../../../actions'
import { selectIsLoaded } from '../../../selectors'


export const useSetupPlayer = (playerId, playlist) => {
  if (!playerId) throw Error('playerId must be provided')
  if (!playlist) throw Error('playlist must be provided')

  if (trueTypeOf(playerId) !== 'string') throw TypeError('playerId must be a string')
  if (trueTypeOf(playlist) !== 'array') throw TypeError('playlist must be an array')

  const dispatch = useDispatch()
  const isLoaded = useSelector(state => selectIsLoaded(state, playerId))

  useEffect(() => {
    if (isLoaded) return

    const player = getPlayer(playerId)
    player.setup(getConfig(playlist))
    player.on('ready', () => {
      const isMute = getIsMute(playerId)
      const item = getCurrentItem(playerId)

      dispatch(playerLoaded({ isMute, item, playerId, playlist }))

      // bind window/tab close events
      window.addEventListener('beforeunload', () => {
        dispatch(playerWindowClosedRequested(playerId))
      })
    })
  }, [ dispatch, isLoaded, playerId, playlist ])
}

