export const getConfig = playlist => ({
  playlist,
  aspectratio: '16:9',
  width: '100%',
  cast: {},
  hlsjsConfig: {
    maxBufferSize: 0,
    maxBufferLength: 30,
    liveSyncDuration: 30,
    liveMaxLatencyDuration: Infinity
  }
})
