import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import PlayerContainer from 'features/player/components/Player/PlayerContainer'
import AdTeaserContainer from 'features/player/components/AdTeaser/AdTeaserContainer'
import PlaylistContainer from 'features/player/components/Playlist/PlaylistContainer'
import ShareDialogContainer from 'features/player/components/ShareDialog/ShareDialogContainer'
import { config } from 'core/config'
import styles from './App.module.css'

function Ui ({ items, playerId, shouldRenderPlaylistMenu }) {
  const playerType = config.player

  return (
    <div className={clsx(styles.ui, shouldRenderPlaylistMenu && styles.aspect)}>
      <div className={styles.uiInner}>
        <PlayerContainer className={styles.player} playerId={playerId} playerType={playerType} items={items}>
          {config.components.adTeaser ? <AdTeaserContainer key='AdTeaserContainer' playerId={playerId} /> : null }
          {config.components.shareDialog ? <ShareDialogContainer key='ShareDialogContainer' appUrl={config.appUrl} facebookAppId={config.facebookAppId} playerId={playerId} playerType={playerType} /> : null }
        </PlayerContainer>
        {shouldRenderPlaylistMenu ? <PlaylistContainer className={styles.playlist} playerId={playerId} playerType={playerType} /> : null}
      </div>
    </div>
  )
}

Ui.propTypes = {
  items: PropTypes.array,
  playerId: PropTypes.string.isRequired,
  shouldRenderPlaylistMenu: PropTypes.bool.isRequired
}

Ui.defaultProps = {
  shouldRenderPlaylistMenu: false
}

export default Ui
