export const NAME = 'tracking'

export const BLIVE = 'blive'
export const GA = 'ga'
export const FACEBOOK = 'fb'

export const SERVICES = { BLIVE, GA, FACEBOOK }

export const EVENTS = {
  PLAYER_LOADED: 14,
  PLAYER_PLAYED: 15,
  VIDEO_ENDED: 2103,
  TIME_WATCHED: 9,
  VIDEO_MOBILE_UNMUTED: 2105,
  VIDEO_FULLSCREEN: 2106,
  TV_PAGE_VIEWED: 2201,
  AD_LOADED: 2601,
  AD_IMPRESSION: 2602,
  AD_PLAYED: 2603,
  AD_FIRST_QUARTILE: 2604,
  AD_MIDPOINT: 2605,
  AD_THIRD_QUARTILE: 2606,
  AD_CLICKED: 2607,
  AD_PAUSED: 2608,
  AD_RESUMED: 2609,
  AD_SKIPPED: 2610,
  AD_CLOSED: 2611,
  AD_ENDED: 2612,
  PLAYLIST_DEPTH: 2701,
  PLAYLIST_TIME: 2702,
  PLAYLIST_TIME_IN_VIEW: 2704,
  PLAYLIST_DEPTH_IN_VIEW: 2705,
  TV_DCM_ENGAGEMENT: 2703,
  TV_CHANNEL_SWITCH: 2801,
  TV_EMBED_INTENT: 2301,
  TV_SHARE_INTENT: 2401
}
