import { BLIVE, FACEBOOK, GA } from './constants'

export const initialState = {
  [ BLIVE ]: {
    endpoint: 'https://pixel.blivenyc.com/tp.gif',
    isActive: false,
    isLoaded: false,
    isReporting: false,
    queue: []
  },
  [ GA ]: {
    id: null,
    isActive: false,
    isLoaded: false,
    isReporting: false,
    queue: []
  },
  [ FACEBOOK ]: {
    isActive: false,
    isLoaded: false,
    isReporting: false,
    queue: []
  },
  campaignId: null,
  isMounted: false,
  sessionKey: null,
  type: 'dcm',
  user: null
}
