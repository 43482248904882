import { PLAYER_TYPES } from './constants'

export const initialState = {
  isLoaded: false,
  isMute: false,
  isRelatedMenuOpen: false,
  isShareDialogOpen: false,
  isTeaserVisible: false,
  isUnmuteVisible: false,
  playerId: null,
  playerState: null,
  playlist: null,
  playlistItemIndex: 0,
  playlistDepthInView: 0,
  time: {
    interval: null,
    current: 0,
    elapsed: 0,
    playlistElapsed: 0,
    inViewElapsed: 0,
    last: null,
    lastElapsed: null,
    lastPlaylistElapsed: null,
    lastInViewElapsed: null
  },
  type: PLAYER_TYPES.DCM
}
