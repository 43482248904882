import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { selectCurrentLabel, selectIsTeaserVisible } from '../../selectors'
import AdTeaser from './AdTeaser'

const AdTeaserContainer = ({ playerId }) => {
  const label = useSelector(state => selectCurrentLabel(state, playerId))
  const isActive = useSelector(state => selectIsTeaserVisible(state, playerId))

  return (
    <AdTeaser
      isActive={isActive}
      label={label}
    />
  )
}

AdTeaserContainer.propTypes = {
  playerId: PropTypes.string.isRequired
}

export default AdTeaserContainer
