import { config } from 'core/config'

export const isDevelopment = config.debug

export const loadScript = url => {
  return new Promise((resolve, reject) => {
      const script = document.createElement('script')
      script.onload = resolve;
      script.onerror = reject;
      script.src = url;
      document.getElementsByTagName('head')[0].appendChild(script)
  })
}

export const isJwplayer = config.player === 'jwplayer'

export const isBitmovin = config.player === 'bitmovin'

export const isBrid = config.player === 'brid'
