import React from 'react'

const FacebookTracker = () => {
  return (
    <div>
      Facebook Tracker
    </div>
  )
}

FacebookTracker.propTypes = {
}

FacebookTracker.defaultProps = {
}

export default FacebookTracker