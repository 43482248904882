import { configureStore } from '@reduxjs/toolkit'
import dcmReducer from '../features/dcm/dcmSlice'
import { reducer as playerReducer } from '../features/player/reducer'
import { reducer as trackingReducer} from '../features/tracking/reducer'

export default configureStore({
  reducer: {
    dcm: dcmReducer,
    player: playerReducer,
    tracking: trackingReducer
  },
});
