import { usePlayerEventHandler } from './index'
import {
  playerAdClicked,
  playerAdCompleted,
  playerAdError,
  playerAdImpression,
  playerAdPlayed,
  playerAdRequested,
  playerAdSkipped,
  playerAdStarted,
  playerBuffered,
  playerEnded,
  playerFullscreenChanged,
  playerIdled,
  playerIsMuteChanged,
  playerPaused,
  playerPlaybackButtonClicked,
  playerPlaylistItem,
  playerRelatedMenuClosed,
  playerRelatedMenuOpened,
  playerVolumeChanged,
} from '../actions'

import {
  playerPlayedRequested,
  playerSeeked
} from '../thunks'

export const usePlayerAdClickEvent = (onEventWrapper, playerId) => usePlayerEventHandler(playerAdClicked, onEventWrapper, playerId)

export const usePlayerAdCompleteEvent = (onEventWrapper, playerId) => usePlayerEventHandler(playerAdCompleted, onEventWrapper, playerId)

export const usePlayerAdErrorEvent = (onEventWrapper, playerId) => usePlayerEventHandler(playerAdError, onEventWrapper, playerId)

export const usePlayerAdImpressionEvent = (onEventWrapper, playerId) => usePlayerEventHandler(playerAdImpression, onEventWrapper, playerId)

export const usePlayerAdPlayEvent = (onEventWrapper, playerId) => usePlayerEventHandler(playerAdPlayed, onEventWrapper, playerId)

export const usePlayerAdRequestEvent = (onEventWrapper, playerId) => usePlayerEventHandler(playerAdRequested, onEventWrapper, playerId)

export const usePlayerAdSkippedEvent = (onEventWrapper, playerId) => usePlayerEventHandler(playerAdSkipped, onEventWrapper, playerId)

export const usePlayerAdStartedEvent = (onEventWrapper, playerId) => usePlayerEventHandler(playerAdStarted, onEventWrapper, playerId)

export const usePlayerBufferedEvent = (onEventWrapper, playerId) => usePlayerEventHandler(playerBuffered, onEventWrapper, playerId)

export const usePlayerEndedEvent = (onEventWrapper, playerId) => usePlayerEventHandler(playerEnded, onEventWrapper, playerId)

export const usePlayerFullscreenChangedEvent = (onEventWrapper, playerId) => usePlayerEventHandler(playerFullscreenChanged, onEventWrapper, playerId)

export const usePlayerIdledEvent = (onEventWrapper, playerId) => usePlayerEventHandler(playerIdled, onEventWrapper, playerId)

export const usePlayerIsMuteChangedEvent = (onEventWrapper, playerId) => {
  const extraParams = ({ isMute }) => ({ isMute })
  return usePlayerEventHandler(playerIsMuteChanged, onEventWrapper, playerId, extraParams)
}

export const usePlayerPausedEvent = (onEventWrapper, playerId) => usePlayerEventHandler(playerPaused, onEventWrapper, playerId)

export const usePlayerPlaybackButtonClickedEvent = (onEventWrapper, playerId) => {
  const extraParams = ({ value }) => ({ value })
  return usePlayerEventHandler(playerPlaybackButtonClicked, onEventWrapper, playerId, extraParams)
}

export const usePlayerPlayedEvent = (onEventWrapper, playerId) => {
  const extraParams = ({ getCurrentItemIndex, getIsMute }) => ({ index: getCurrentItemIndex(), isMute: getIsMute() })
  return usePlayerEventHandler(playerPlayedRequested, onEventWrapper, playerId, extraParams)
}

export const usePlayerPlaylistEvent = (onEventWrapper, playerId) => {
  const extraParams = ({ index, getIsInView }) => ({ index, isInView: getIsInView() })
  return usePlayerEventHandler(playerPlaylistItem, onEventWrapper, playerId, extraParams)
}

export const usePlayerRelatedMenuCloseEvent = (onEventWrapper, playerId) => usePlayerEventHandler(playerRelatedMenuClosed, onEventWrapper, playerId)

export const usePlayerRelatedMenuOpenEvent = (onEventWrapper, playerId) => usePlayerEventHandler(playerRelatedMenuOpened, onEventWrapper, playerId)

export const usePlayerSeekedEvent = (onEventWrapper, playerId) => {
  const extraParams = ({ time, getIsInView }) => ({ time, isInView: getIsInView() })
  return usePlayerEventHandler(playerSeeked, onEventWrapper, playerId, extraParams)
}

export const usePlayerVolumeChangedEvent = (onEventWrapper, playerId) => usePlayerEventHandler(playerVolumeChanged, onEventWrapper, playerId)
