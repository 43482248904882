export const config = {
  debug: process.env.NODE_ENV === 'development',
  client: process.env.REACT_APP_CLIENT,
  appUrl: process.env.REACT_APP_URL,
  player: process.env.REACT_APP_PLAYER,
  bitmovin: {
    key: process.env.REACT_APP_BITMOVIN_KEY
  },
  brid: {
    key: process.env.REACT_APP_BRID_KEY
  },
  jwplayer: {
    key: process.env.REACT_APP_JWPLAYER_KEY,
    libraryKey: process.env.REACT_APP_JWPLAYER_LIBRARY,
  },
  components: {
    adTeaser: process.env.REACT_APP_IS_WITH_AD_TEASER === 'true',
    shareDialog: process.env.REACT_APP_IS_WITH_SHARE_DIALOG === 'true',
    playlistMenu: process.env.REACT_APP_IS_WITH_PLAYLIST_MENU === 'true'
  },
  dcmUrl: process.env.REACT_APP_DCM_BASE_URL,
  tracking: {
    ga: process.env.REACT_APP_GA_ID
  },
  facebookAppId: parseInt(process.env.REACT_APP_FACEBOOK_ID, 10),
  images: {
    loading: {
      desktop: process.env.REACT_APP_LOADING_IMAGE_DESKTOP,
      mobile: process.env.REACT_APP_LOADING_IMAGE_MOBILE
    }
  }
}