import { NAME } from './constants'

export const selectSlice = state => state[NAME]
export const selectByName = (state, name) => selectSlice(state) && selectSlice(state)[name]
export const selectCampaignId = state => selectSlice(state).campaignId
export const selectEndpoint = (state, name) => selectParamByName(state, name, 'endpoint')
export const selectId = (state, name) => selectParamByName(state, name, 'id')
export const selectIsLoaded = (state, name) => selectParamByName(state, name, 'isLoaded')
export const selectIsMounted = state => selectSlice(state).isMounted
export const selectParamByName = (state, name, param) => selectSlice(state) && selectSlice(state)[name] && selectSlice(state)[name][param]
export const selectSessionKey = state => selectSlice(state).sessionKey
export const selectQueue = (state, name) => selectParamByName(state, name, 'queue')
export const selectUser = state => selectSlice(state).user
