import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import {
  useAdEvents,
  useJwplayerEvents,
  usePlaybackButtonClickedEvent,
  useRelatedMenuEvents,
  useSetupPlayer,
  useUpdateTime
} from './hooks'
import styles from '../Player.module.css'

const JWPlayer = ({ playlist, playerId }) => {
  const playerEl = useRef(null)

  // set up player
  useSetupPlayer(playerId, playlist)

  // keep track of time every second
  useUpdateTime(playerId)

  // bind native jwplayer events
  useJwplayerEvents(playerId)

  // bind native jwplayer ad events
  useAdEvents(playerId)

  // bind playback button click event
  usePlaybackButtonClickedEvent(playerId)

  // bind related menu events
  useRelatedMenuEvents(playerId)

  return (
    <div className={styles.player}>
      <div id={playerId} ref={playerEl} />
    </div>
  )
}

JWPlayer.propTypes = {
  playlist: PropTypes.arrayOf(PropTypes.shape({
    description: PropTypes.string,
    file: PropTypes.string,
    isLive: PropTypes.bool,
    link: PropTypes.string,
    mediaid: PropTypes.string,
    slug: PropTypes.string
  })),
  playerId: PropTypes.string.isRequired
}

export default JWPlayer
