import { default as trueTypeOf } from '@bit/orzubalsky.blive.utils.true-type-of'

const makeDcmUrl = ({ params, client, dcmUrl }) => {
  if (!client) throw Error('client argument missing')
  if (!params) throw Error('params argument missing')
  if (!dcmUrl) throw Error('dcmUrl argument missing')

  if (trueTypeOf(client) !== 'string') throw TypeError('client must be a string')
  if (trueTypeOf(params) !== 'object') throw TypeError('params must be an object')
  if (trueTypeOf(dcmUrl) !== 'string') throw TypeError('dcmUrl must be a string')

  if (!['akc', 'pg'].includes(client)) throw Error('client must be either akc or pg')

  const pageUrl = params.url || window.location.href

  let urlParams

  if (client === 'akc') {
    if (!params.id) throw Error('id param must be provided for akc')

    const { id, url, ...restOfParams } = params

    urlParams = Object.keys(restOfParams).map((key, i) => `${key}/${Object.values(restOfParams)[i]}`).join('/')

    return `${dcmUrl}${urlParams}/campaign/${params.id}/url/${btoa(pageUrl)}`
  }

  if (client === 'pg') {
    if (!params.campaign) throw Error('campaign param must be provided for pg')

    urlParams = Object.keys(params).map((key, i) => `${key}/${Object.values(params)[i]}`).join('/')

    return `${dcmUrl}${urlParams}/url/${btoa(pageUrl)}`
  }
}

export default makeDcmUrl