import { GA } from '../constants'
import { reportGa } from '../thunks'
import useGa from '../hooks/useGa'
import useReportQueue from '../hooks/useReportQueue'

const GaTracker = () => {
  useGa()

  useReportQueue(GA, reportGa)

  return null
}

export default GaTracker