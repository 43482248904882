import { useDispatch, useSelector } from 'react-redux'
import trueTypeOf from '@bit/orzubalsky.blive.utils.true-type-of'
import { useInterval } from '../useInterval/useInterval'
import { setTime } from '../../actions'
import { selectCurrentIsLive, selectCurrentTime, selectIsLoaded } from '../../selectors'

export const usePlayerTimeInterval = ({ getIsNotPlaying, getIsPlayerInView, getPlayerCurrentTime, playerId }) => {
  if (!getIsNotPlaying) throw Error('getIsNotPlaying must be provided')
  if (!getIsPlayerInView) throw Error('getIsPlayerInView must be provided')
  if (!getPlayerCurrentTime) throw Error('getPlayerCurrentTime must be provided')

  if (trueTypeOf(getIsNotPlaying) !== 'function') throw TypeError('getIsNotPlaying must be a function')
  if (trueTypeOf(getIsPlayerInView) !== 'function') throw TypeError('getIsPlayerInView must be a function')
  if (trueTypeOf(getPlayerCurrentTime) !== 'function') throw TypeError('getPlayerCurrentTime must be a function')

  const dispatch = useDispatch()

  const isLoaded = useSelector(state => selectIsLoaded(state, playerId))
  const currentTime = useSelector(state => selectCurrentTime(state, playerId))
  const isCurrentItemLive = useSelector(state => selectCurrentIsLive(state, playerId))

  useInterval(() => {
    if (!isLoaded) return

    if (getIsNotPlaying()) return

    let time = 0

    time = isCurrentItemLive
      ? currentTime + 1
      : Math.round(getPlayerCurrentTime())

    if (currentTime !== time) {
      dispatch(setTime({ playerId, seconds: time, isInView: getIsPlayerInView() }))
    }
  }, 1000)
}
