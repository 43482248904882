export const initialState = {
  client: null,
  dcmUrl: null,
  isFetching: false,
  isLoaded: false,
  items: [],
  loadingImage: {
    desktop: null,
    mobile: null
  },
  player: null
}
