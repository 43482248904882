import trueTypeOf from '@bit/orzubalsky.blive.utils.true-type-of'
import { NAME, PLAYER_TYPES } from './constants'

export const selectSlice = state => state[NAME]
export const selectParam = (state, id, param) => selectSlice(state) && selectSlice(state)[id] && selectSlice(state)[id][param]
export const selectCurrentIsLive = (state, id) => selectCurrentItem(state, id).isLive
export const selectCurrentItem = (state, id) => {
  const index = selectParam(state, id, 'playlistItemIndex')
  return selectItem(state, id, index)
}
export const selectCurrentTime = (state, id) => selectTime(state, id).current
export const selectCurrentLabel = (state, id) => selectCurrentItem(state, id).label
export const selectCurrentLink = (state, id) => selectCurrentItem(state, id).link
export const selectCurrentSlug = (state, id) => selectCurrentItem(state, id).slug
export const selectDidCurrentItemPlay = (state, id) => selectCurrentItem(state, id).didPlay
export const selectInViewElapsed = (state, id) => selectTime(state, id).inViewElapsed
export const selectIsAppPlayer = (state, id) => selectPlayerType(state, id) === PLAYER_TYPES.APP
export const selectIsDcmPlayer = (state, id) => selectPlayerType(state, id) === PLAYER_TYPES.DCM
export const selectIsLoaded = (state, id) => selectParam(state, id, 'isLoaded')
export const selectIsMounted = (state, id) => selectParam(state, id, 'isMounted')
export const selectIsMute = (state, id) => selectParam(state, id, 'isMute')
export const selectIsRelatedMenuOpen = (state, id) => selectParam(state, id, 'isRelatedMenuOpen')
export const selectIsShareDialogOpen = (state, id) => selectParam(state, id, 'isShareDialogOpen')
export const selectIsShareDialogVisible = (state, id) => !selectIsRelatedMenuOpen(state, id)
export const selectIsTeaserVisible = (state, id) => selectParam(state, id, 'isTeaserVisible')
export const selectIsUnmuteVisible = (state, id) => {
  // button is hidden before any item was played
  if (!selectItemDidPlay(state, id)) return false

  // button is hidden when a menu is open
  if (selectIsRelatedMenuOpen(state, id) || selectIsShareDialogOpen(state, id)) return false

  // button state depends on player mute state
  return selectIsMute(state, id)
}
export const selectItem = (state, id, index) => {
  if (index !== 0 && !index) throw Error('index must be provided')
  if (trueTypeOf(index) !== 'number') throw TypeError('index must be a number')

  const playlist = selectPlaylist(state, id)

  return playlist && playlist[index] ? playlist[index] : {}
}
export const selectItemDidPlay = (state, id) => {
  const playlist = selectPlaylist(state, id)
  return playlist ? !!playlist.find(item => item.didPlay) : false
}
export const selectPlayerId = (state, id) => selectParam(state, id, 'playerId')
export const selectPlayerState = (state, id) => selectParam(state, id, 'playerState')
export const selectPlayerType = (state, id) => selectParam(state, id, 'playerType')
export const selectPlaylist = (state, id) => selectParam(state, id, 'playlist')
export const selectPlaylistDepthInView = (state, id) => selectParam(state, id, 'playlistDepthInView')
export const selectPlaylistElapsed = (state, id) => selectTime(state, id).playlistElapsed
export const selectPlaylistItemIndex = (state, id) => selectParam(state, id, 'playlistItemIndex')
export const selectTime = (state, id) => selectParam(state, id, 'time')
