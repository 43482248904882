import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { selectLoadingImage } from '../dcmSlice'
import LoadingImage from './LoadingImage'

const LoadingImageContainer = ({ playerId }) => {
  const src = useSelector(state => selectLoadingImage(state, playerId))

  if (!src || !src.desktop) return null

  return <LoadingImage src={src.desktop} />
}

LoadingImageContainer.propTypes = {
  playerId: PropTypes.string.isRequired
}

export default LoadingImageContainer