import { createAction } from '@reduxjs/toolkit'
import { NAME } from './constants'

export const playerAdClicked = createAction(`${NAME}/adClicked`)
export const playerAdCompleted = createAction(`${NAME}/adCompleted`)
export const playerAdError = createAction(`${NAME}/adError`)
export const playerAdImpression = createAction(`${NAME}/adImpression`)
export const playerAdPlayed = createAction(`${NAME}/adPlayed`)
export const playerAdRequested = createAction(`${NAME}/adRequested`)
export const playerAdSkipped = createAction(`${NAME}/adSkipped`)
export const playerAdStarted = createAction(`${NAME}/adStarted`)
export const playerBuffered = createAction(`${NAME}/buffered`)
export const playerEmbedIntended = createAction(`${NAME}/embedInteded`)
export const playerEnded = createAction(`${NAME}/ended`)
export const playerFullscreenChanged = createAction(`${NAME}/fullscreenChanged`)
export const playerIdled = createAction(`${NAME}/idled`)
export const playerIsMuteChanged = createAction(`${NAME}/isMuteChanged`)
export const playerLoaded = createAction(`${NAME}/loaded`)
export const playerMounted = createAction(`${NAME}/mounted`)
export const playerPaused = createAction(`${NAME}/paused`)
export const playerPlaybackButtonClicked = createAction(`${NAME}/playbackButtonClicked`)
export const playerPlayed = createAction(`${NAME}/played`)
export const playerPlaylistItem = createAction(`${NAME}/playlistItem`)
export const playerRelatedMenuOpened = createAction(`${NAME}/relatedMenuOpened`)
export const playerRelatedMenuClosed = createAction(`${NAME}/relatedMenuClosed`)
export const playerShareDialogClosed = createAction(`${NAME}/shareDialogClosed`)
export const playerShareDialogOpened = createAction(`${NAME}/shareDialogOpened`)
export const playerShareIntended = createAction(`${NAME}/shareIntended`)
export const playerVolumeChanged = createAction(`${NAME}/volumeChanged`)
export const playerWindowClosed = createAction(`${NAME}/windowClosed`)
export const resetTime = createAction(`${NAME}/resetTime`)
export const setTime = createAction(`${NAME}/setTime`)
