import { BLIVE } from '../constants'
import { reportBlive } from '../thunks'
import useReportQueue from '../hooks/useReportQueue'
import useSessionKey from '../hooks/useSessionKey'
import useUserAgent from '../hooks/useUserAgent'

const BliveTracker = () => {
  useUserAgent()
  useSessionKey()
  useReportQueue(BLIVE, reportBlive)

  return null
}

export default BliveTracker