import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { playerMounted } from '../../actions'
import { selectIsMounted, selectPlaylist } from '../../selectors'
import Player from './Player'

const PlayerContainer = ({ children, className, items, playerId, playerType }) => {
  const isMounted = useSelector(state => selectIsMounted(state, playerId))
  const playlist = useSelector(state => selectPlaylist(state, playerId))

  const dispatch = useDispatch()

  useEffect(() => {
    if (isMounted) return

    dispatch(playerMounted({ items, playerType, playerId }))
  }, [ dispatch, isMounted, items, playerType, playerId ])

  return (
    <Player
      className={className}
      items={playlist}
      playerId={playerId}
      playerType={playerType}
    >
      {children}
    </Player>
  )
}

PlayerContainer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  items: PropTypes.array,
  playerId: PropTypes.string.isRequired,
  playerType: PropTypes.string.isRequired
}

export default PlayerContainer