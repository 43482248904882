import React, { cloneElement, useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import LoadingImageContainer from './LoadingImageContainer'
import styles from './Dcm.module.css'

const Dcm = ({ children, items, playerId }) => {
  const shouldBeLoading = useCallback(() => (!items || items.length === 0) || !children, [ children, items ])

  const [ isLoading, setIsLoading ] = useState(shouldBeLoading)

  useEffect(() => {
    setIsLoading(shouldBeLoading)
  }, [ shouldBeLoading ])


  return (
    <div className={clsx(styles.root, isLoading && styles.loading)}>
      {isLoading
        ? <LoadingImageContainer playerId={playerId} />
        : children.length ? children.map(child => cloneElement(child, { items })) : cloneElement(children, { items })
      }
    </div>
  )
}

Dcm.propTypes = {
  children: PropTypes.node,
  playerId: PropTypes.string.isRequired,
  urlParams: PropTypes.object,
}

export default Dcm