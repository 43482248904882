import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import styles from '../Player.module.css'

const AdTeaser = ({ isActive, label }) => {
  const [ timeout, setTeaserTimeout ] = useState(null)
  const [ isVisible, setIsVisible ] = useState(false)

  useEffect(() => {
    const hide = () => {
      setIsVisible(false)
      clearTimeout(timeout)
    }

    const show = () => {
      clearTimeout(timeout)
      setIsVisible(true)
      setTeaserTimeout(setTimeout(hide, 30000))
    }

    if (isActive && !isVisible && !timeout) {
      show()
    }

    if (!isActive) {
      hide()
    }
  }, [ isActive, isVisible, timeout ])

  const classes = clsx(styles.teaser, isVisible && styles.teaserActive)

  if (!label) return null

  return (
    <div className={classes}>
      "{label}" will play after the ad
    </div>
  )
}

AdTeaser.propTypes = {
  isActive: PropTypes.bool.isRequired,
  label: PropTypes.string
}

AdTeaser.defaultProps = {
  isActive: false
}

export default AdTeaser
