import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
// import BridPlayer from '../BridPlayer/BridPlayer'
import JWPlayer from '../JWPlayer/JWPlayer'
import styles from '../Player.module.css'

const players = {
  // brid: BridPlayer,
  jwplayer: JWPlayer
}

const Player = ({ children, className, items, playerId, playerType }) => {
  if (!items || items.length <= 0) return null

  const PlayerComponent = players[playerType]

  if (!PlayerComponent) return null

  return (
    <div className={clsx(className, styles.root)}>
      <PlayerComponent playerId={playerId} playlist={items} />
      {children}
    </div>
  )
}

Player.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  items: PropTypes.array,
  playerId: PropTypes.string.isRequired,
  playerType: PropTypes.string.isRequired
}

export default Player