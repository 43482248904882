import { getCurrentItem } from '../utils'
import { usePlayerPlaybackButtonClickedEvent } from '../../../hooks'


export const usePlaybackButtonClickedEvent = playerId => {
  const onEventWrapper = handler => {
    const playbackButton = document.querySelector(`#${playerId} .jw-icon-playback`)

    if (playbackButton) {
      playbackButton.addEventListener('click', () => {
        const value = playbackButton.getAttribute('aria-label') === 'Pause' ? 'pause' : 'resume';
        handler({ getCurrentItem: () => getCurrentItem(playerId), value, playerId })
      })
    }
  }

  usePlayerPlaybackButtonClickedEvent(onEventWrapper, playerId)
}

