import { playerPlayed, setTime, playerWindowClosed } from './actions'
import { selectCurrentItem, selectDidCurrentItemPlay, selectInViewElapsed, selectPlaylistDepthInView, selectPlaylistElapsed } from './selectors'


// THUNKS
export const playerPlayedRequested = ({ playerId, index, isMute }) => (dispatch, getState) => {
  const item = selectCurrentItem(getState(), playerId)
  const itemDidPlay = selectDidCurrentItemPlay(getState(), playerId)

  dispatch(playerPlayed({ playerId, index, isMute, item, itemDidPlay }))
}

export const playerSeeked = ({ time, isInView, playerId }) => dispatch => {
  dispatch(setTime({ playerId, seconds: Math.round(time), isInView }))
}

export const playerWindowClosedRequested = playerId => (dispatch, getState) => {
  const item = selectCurrentItem(getState(), playerId)
  const playlistElapsed = selectPlaylistElapsed(getState(), playerId)
  const inViewElapsed = selectInViewElapsed(getState(), playerId)
  const playlistDepthInView = selectPlaylistDepthInView(getState(), playerId)

  dispatch(playerWindowClosed({ playerId, item, playlistElapsed, playlistDepthInView, inViewElapsed }))
}
