import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import loadJs from 'fg-loadjs'
import { GA } from '../constants'
import { selectId } from '../selectors'
import { gaLoaded } from '../actions'

const useGa = () => {
  const dispatch = useDispatch()

  const gaId = useSelector(state => selectId(state, GA))

  useEffect(() => {
    loadJs(`https://www.googletagmanager.com/gtag/js?id=${gaId}`, () => {
      // set google gtag
      if (!window.gtag) {
        window.dataLayer = window.dataLayer || [];
        window.gtag = function() { window.dataLayer.push(arguments) }
        window.gtag('js', new Date())
      }

      window.gtag && window.gtag('config', gaId)
      window.gtag && window.gtag('set', { cookie_flags: 'SameSite=None;Secure' })

      dispatch(gaLoaded())
    })
  }, [ dispatch, gaId ])
}

export default useGa