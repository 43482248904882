import { randomString } from './index'

export const itemParams = (state, action) => {
  const { item, index } = action.payload

  let itemId
  let itemLabel
  let itemDidPlay

  if (item) {
    itemDidPlay = item.didPlay
    itemId = parseInt(item.mediaid, 10)
    itemLabel = item.label
  }

  const id = randomString()

  return { id, index, item, itemDidPlay, itemId, itemLabel }
}
