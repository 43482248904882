import { BLIVE, GA } from './constants'
import { setUser, removeFromQueue } from './actions'
import { selectQueue, selectCampaignId, selectEndpoint, selectIsLoaded, selectSessionKey, selectUser } from './selectors'


export const fetchUser = () => async dispatch => {
  const user = await fetch('//tv-api.blivenyc.com/get-user-agent')
    .then(response => response.json())
    .then(json => json.data)

  dispatch(setUser({ user }))
}

export const reportQueue = (name, reportFunc) => (dispatch, getState) => {
  const currentQueue = selectQueue(getState(), name)

  currentQueue.forEach(async event => {
    const id = await reportFunc(event)
    dispatch(removeFromQueue({ id, name }))
  })
}

export const reportBlive = () => (dispatch, getState) => {
  const campaignId = selectCampaignId(getState())
  const endpoint = selectEndpoint(getState(), BLIVE)
  const isLoaded = selectIsLoaded(getState(), BLIVE)
  const sessionKey = selectSessionKey(getState())
  const user = selectUser(getState())

  dispatch(reportQueue(BLIVE, item => {
    return new Promise((resolve, reject) => {
      if (!isLoaded) reject(Error('User agent not loaded'))

      const { hostname, href } = document.location

      const params = {
        event_type_id: item.etid,
        event_value: item.val,
        external_object_id: item.eoid,
        event_time: Math.round((new Date()).getTime() / 1000),
        campaign_id: campaignId,
        campaign_status_id: 5,
        embed_domain: hostname,
        embed_url: href,
        js_session_key: sessionKey,
        ...user
      }

      const stringifiedParams = Object.keys(params).map(key => `${key}=${encodeURIComponent(params[key])}`).join('&')

      const url = `${endpoint}?${stringifiedParams}`

      let xhr = new XMLHttpRequest()

      xhr.open('GET', url, true)

      xhr.onload = () => {
        if (xhr.status !== 200) reject(Error('[BliveTracker] Pixel hit failed'))

        resolve(item.id)
      }

      xhr.send()
    })
  }))
}

export const reportGa = () => dispatch => {
  dispatch(reportQueue(GA, item => {
    return new Promise((resolve, reject) => {
      if (!window.gtag) reject(Error('[BliveTracker] GA not loaded'))

      window.gtag('event', item.event_category, item)

      resolve(item.id)
    })
  }))
}
