// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".Player_root__350HG {\r\n  padding-bottom: 56.25%;\r\n  height: 0;\r\n  position: relative;\r\n  z-index: 100;\r\n}\r\n\r\n.Player_player__2wGbf {\r\n  position: absolute !important;\r\n  top: 0;\r\n  right: 0;\r\n  bottom: 0;\r\n  left: 0;\r\n  height: 100%;\r\n  width: 100%;\r\n  z-index: 1;\r\n}\r\n\r\n.Player_teaser__2VC-J {\r\n  background: rgba(0, 0, 0, 0.75);\r\n  color: rgba(240, 240, 240, 1);\r\n  position: absolute;\r\n  top: 0;\r\n  left: 0;\r\n  padding: 10px;\r\n  width: 100%;\r\n  z-index: 0;\r\n  font-family: \"Gotham\";\r\n  font-size: 14px;\r\n  opacity: 0;\r\n  transition: all 0.3s;\r\n}\r\n\r\n.Player_teaser__2VC-J.Player_teaserActive__23rC3 {\r\n  z-index: 100;\r\n  opacity: 1;\r\n}\r\n", "",{"version":3,"sources":["Player.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,SAAS;EACT,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,6BAA6B;EAC7B,MAAM;EACN,QAAQ;EACR,SAAS;EACT,OAAO;EACP,YAAY;EACZ,WAAW;EACX,UAAU;AACZ;;AAEA;EACE,+BAA+B;EAC/B,6BAA6B;EAC7B,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,aAAa;EACb,WAAW;EACX,UAAU;EACV,qBAAqB;EACrB,eAAe;EACf,UAAU;EACV,oBAAoB;AACtB;;AAEA;EACE,YAAY;EACZ,UAAU;AACZ","file":"Player.module.css","sourcesContent":[".root {\r\n  padding-bottom: 56.25%;\r\n  height: 0;\r\n  position: relative;\r\n  z-index: 100;\r\n}\r\n\r\n.player {\r\n  position: absolute !important;\r\n  top: 0;\r\n  right: 0;\r\n  bottom: 0;\r\n  left: 0;\r\n  height: 100%;\r\n  width: 100%;\r\n  z-index: 1;\r\n}\r\n\r\n.teaser {\r\n  background: rgba(0, 0, 0, 0.75);\r\n  color: rgba(240, 240, 240, 1);\r\n  position: absolute;\r\n  top: 0;\r\n  left: 0;\r\n  padding: 10px;\r\n  width: 100%;\r\n  z-index: 0;\r\n  font-family: \"Gotham\";\r\n  font-size: 14px;\r\n  opacity: 0;\r\n  transition: all 0.3s;\r\n}\r\n\r\n.teaser.teaserActive {\r\n  z-index: 100;\r\n  opacity: 1;\r\n}\r\n"]}]);
// Exports
exports.locals = {
	"root": "Player_root__350HG",
	"player": "Player_player__2wGbf",
	"teaser": "Player_teaser__2VC-J",
	"teaserActive": "Player_teaserActive__23rC3"
};
module.exports = exports;
