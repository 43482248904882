import { useCallback, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import trueTypeOf from '@bit/orzubalsky.blive.utils.true-type-of'
import { selectIsLoaded } from '../../selectors'

export const usePlayerEventHandler = (action, onEventWrapper, playerId, extraParams = () => ({})) => {
  // validate arguments
  if (!action) throw Error('action must be provided')
  if (!onEventWrapper) throw Error('onEventWrapper must be provided')
  if (!playerId) throw Error('playerId must be provided')

  // validate argument types
  if (trueTypeOf(action) !== 'function') throw TypeError('action must be a function')
  if (trueTypeOf(onEventWrapper) !== 'function') throw TypeError('onEventWrapper must be a function')
  if (trueTypeOf(playerId) !== 'string') throw TypeError('playerId must be a string')

  const dispatch = useDispatch()

  // placeholder for function to call when isLoaded is true
  const savedCallback = useRef()

  // define function to call when is Loaded is true
  const callback = useCallback(() => {
    // action passes item and playerId by default
    const getParams = ({ getCurrentItem, playerId } = {}) => ({ item: getCurrentItem && getCurrentItem(), playerId })

    // hook up the event handler to the player's on event wrapper
    // the wrapper is abstracted because it's different for different players
    // for example, for jwplayer it's player.on('play', () => {})
    onEventWrapper(params => dispatch(action({ ...getParams(params), ...extraParams(params) })))
  }, [ action, dispatch, extraParams, onEventWrapper ])

  // get isLoaded from the state since events can only be handled
  // after player is ready
  const isLoaded = useSelector(state => selectIsLoaded(state, playerId))

  // remember the latest callback
  useEffect(() => {
    savedCallback.current = callback
  }, [ callback ])

  // bind the event when player switches from isLoaded false to true
  useEffect(() => {
    if (!isLoaded) return

    savedCallback.current()
  }, [ isLoaded ])
}
